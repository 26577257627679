// extracted by mini-css-extract-plugin
export var courses__label = "teachers-module--courses__label--yxrzx";
export var courses__list = "teachers-module--courses__list--+Q66p";
export var form__courses = "teachers-module--form__courses--YRu7p";
export var form__submit = "teachers-module--form__submit--jRnv5";
export var item__details = "teachers-module--item__details--a9YSw";
export var item__name = "teachers-module--item__name--vTIFA";
export var item__subjects = "teachers-module--item__subjects--Pz6c2";
export var loading = "teachers-module--loading--uvFac";
export var modal__content = "teachers-module--modal__content--l5ovZ";
export var modal__form = "teachers-module--modal__form--np1fr";
export var modal__header = "teachers-module--modal__header--7+lrv";
export var table = "teachers-module--table--tXZrv";
export var teachers__edit = "teachers-module--teachers__edit--Lp6Sl";
export var teachers__title = "teachers-module--teachers__title--+-5yh";